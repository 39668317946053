@import '../abstracts/variables';
@import '../abstracts/extend';

.custom-upload {
  transition: all 0.3s ease;
  border: 2px dashed rgba(17, 1, 161);
  border-radius: 5px;
  justify-content: center;
  display: flex;
  padding: 40px;
  color: #efefef;
  width: 120px;
  height: 120px;
  overflow: hidden;
  align-items: center;
  &:has(.fileUp.invalid) {
    border-color: #f7685d;
  }

  span {
    svg {
      // @include font-size(24px);
      //   @include add-prefix(transition, $transition);
    }
  }

  input {
    @extend %p-abs, %w-100;
    left: 0;
    top: 0;
    font-size: 120px;
    opacity: 0;
    cursor: pointer;
  }

  &:hover {
    border-color: $blue-color;
    cursor: pointer;

    span {
      svg {
        color: $blue-color;
        cursor: pointer;
      }
    }
  }

  &.invalid {
    border-color: #f7685d;
  }
}

.upload {
  &__list {
    @extend %flex, %justify-between;
    gap: 20px;
  }

  &__item {
    > p {
      @extend %mb-0, %mt-10;
      color: $blue-color;
    }
  }
}
