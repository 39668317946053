@import '../abstracts/variables';
@import '../abstracts/mixins';
@import '../abstracts/extend';
@import '../abstracts/functions';
@import '../base/helpers';
// importing core styling file

$theme-color: #0842ac;
$text-color: #fff;

.kyc {
  &__form {
    max-width: 500px;
    background-color: #fff;
    margin: 0 auto;
    box-shadow: 0px 6px 18px rgb(0 0 0 / 9%);
    border-radius: 12px;

    .form-header {
      padding: 40px;
      gap: 5px;
      text-align: center;
      font-size: 1rem;
      border-bottom: 2px solid #eee;

      .stepIndicator {
        @extend %p-rel, %pb-30;
        @include add-prefix(transition, $transition);
        flex: 1;

        &::before {
          @extend %p-abs;
          @include add-prefix(transform, translateX(-50%));
          @include add-prefix(transition, $transition);
          @include box(20px);
          left: 50%;
          bottom: 0;
          z-index: 9;
          background-color: $ash-color;
          border-radius: 5px;
          border: 2px solid rgba($theme-color, $alpha: 0.3);
        }

        &::after {
          @extend %p-abs, %w-100;
          @include add-prefix(transition, $transition);
          left: 50%;
          bottom: 8px;
          height: 3px;
          background-color: rgba($theme-color, $alpha: 0.2);
        }

        &:last-child:after {
          display: none;
        }

        &.active {
          font-weight: 600;
          color: $blue-color;

          &::before {
            background-color: $theme-color;
            border: 2px solid rgba($theme-color, $alpha: 0.1);
          }

          &::after {
            background-color: rgba($theme-color, $alpha: 0.5);
          }
        }

        &.finish {
          font-weight: 600;
          color: $theme-color;

          &::before {
            background-color: $theme-color;
            border: 3px solid rgba($theme-color, $alpha: 0.1);
            content: 'check';
            color: $white-color;
            // font-family: 'Font Awesome 6 Free';
            font-family: 'Material Icons Round';
            font-size: 12px;
            line-height: 15px;
          }

          &::after {
            background-color: $theme-color;
          }
        }
      }
    }

    .form-group {
      h6 {
        @extend %mb-15;
        color: $blue-color;
      }
    }

    .stepIndicator,
    .form-check {
      color: $blue-color;
    }
    .form-control,
    .form-select {
      @extend %w-100;
      @include add-prefix(transition, $transition);
      padding: 10px 20px;
      border: 1px solid rgba($theme-color, $alpha: 0.3);
      border-radius: 5px;
      background-color: #eee;
      color: $blue-color;

      &:focus {
        border-color: $theme-color;
        outline: 0;
        box-shadow: none;
      }

      &.invalid {
        border: 1px solid #f7685d;
      }
    }

    .form-check {
      &:not(:last-child) {
        @extend %mb-10;
        color: $blue-color;
      }

      &-input {
        background-color: $blue-color;
        accent-color: $theme-color;

        &:checked {
          border-color: $theme-color;
        }

        &:focus {
          box-shadow: none;
        }
      }

      a {
        font-weight: 700;
        color: $blue-color;

        &:hover {
          color: $theme-color;
        }
      }
    }

    .step {
      @extend %p-30;
      padding-block-end: 0;
      display: none;

      @include breakpoint(sm) {
        padding: 40px;
      }

      h4 {
        @extend %p-rel, %mb-50;
        color: $blue-color;

        &::after {
          @extend %p-abs;
          @include add-prefix(transform, translateX(-50%));
          height: 2px;
          width: 80px;
          background-color: $theme-color;
          bottom: -10px;
          left: 50%;
        }
        @media screen and (max-width: 768px) {
          font-size: 0.8rem;
        }
      }
    }

    .form-footer {
      padding: 40px;
      padding-block-start: 0;
      overflow: auto;
      gap: 20px;

      button {
        @include add-prefix(transition, $transition);
        background-color: $theme-color;
        // border: 1px solid $theme-color;
        color: $white-color;
        padding: 13px 30px;
        font-size: 1em;
        cursor: pointer;
        border-radius: 5px;
        flex: 1;
        margin-top: 10px;

        &:hover {
          background-color: darken($theme-color, $amount: 10);
        }
      }

      #prevBtn {
        background-color: transparent;
        color: $theme-color;
        border: 1px solid $theme-color;
      }
    }
  }
}

.rules {
  &__item {
    color: rgba($text-color, $alpha: 0.3);

    &:not(:last-child) {
      @extend %mb-5;
    }

    span {
      @extend %mr-5;
    }

    &--active {
      color: $blue-color;

      span {
        svg {
          color: $theme-color;
        }
      }
    }
  }
}

ul {
  padding: 0px !important;
  list-style: none;
}
.rules {
  &__item {
    // color: rgba(#c1c7cb, $alpha: 0.3);
    color: rgba(#0842ac, $alpha: 0.6);
    list-style: none !important;

    &:not(:last-child) {
      @extend %mb-5;
      list-style: none !important;
    }

    span {
      @extend %mr-5;
    }

    &--active {
      color: $blue-color;

      span {
        svg {
          color: $theme-color;
        }
      }
    }
  }
}

.form-check-input:checked[type='checkbox'] {
  background-color: $body-color;
}
.rules__item span {
  margin-top: 5px !important;
  padding-top: 5px !important;
}

@media screen and (max-width: 786px) {
  body,
  .form-select,
  h6 {
    font-size: 0.8rem !important;
  }
  .custom-upload {
    padding: 50px;
    width: 60px;
    height: 60px;
  }
  .kyc__form .form-footer button {
    padding: 13px 20px;
    font-size: 0.8em;
    min-width: 100px;
  }
}

@media screen and (max-width: 786px) {
  .form-header {
    padding: 40px 20px !important;
    font-size: 0.7rem !important;
  }
}
