img {
  vertical-align: top !important;
}
.css-eimhud-MuiSvgIcon-root svg {
  vertical-align: top !important;
}
.uploadError {
  transition: ease-in 0.9ms all;
  color: red;
  font-size: 14px;
  padding: 4px 10px;
  border-radius: 4px;
}
.success-main {
  color: #12ff4d;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.MuiIconButton-root:hover {
  background-color: #fff !important;
  color: #333;
}
.input[type='checkbox'] {
  background-color: red !important;
}
.css-1vn4a3n:hover {
  box-shadow: 0rem 0.5rem 1.25rem 0rem rgba(0, 0, 0, 0.08);
}
.w3m-overlay {
  z-index: 9999 !important;
  background-color: red !important;
}
.css-m6bmkn-MuiPaper-root-MuiAppBar-root,
.css-u0k6ft-MuiPaper-root-MuiDrawer-paper {
  z-index: 88 !important;
}

a.active .css-rufnm2-MuiListItemIcon-root,
a.active .css-rufnm2-MuiListItemIcon-root svg,
a.active .css-rufnm2-MuiListItemIcon-root svg g {
  background-color: #0842ac !important;
  fill: #fff !important;
  color: #fff !important;
}

.form-icon-pass {
  font-size: 1.2em;
  position: absolute;
  right: 15px;
  top: 39px;
}
.form-icon-pass-signup {
  font-size: 1.2em;
  position: absolute;
  right: 10px;
  top: 7px;
}
.css-1j6l4t5 {
  width: calc(100%) !important;
}

@media screen and (max-width: 786px) {
  .form-icon-pass-signup {
    top: 10px;
  }
  .form-icon-pass {
    top: 38px;
    right: 15px;
  }
}

div.form-control.StripeElement,
div.form-control.StripeElement.StripeElement--invalid,
div.form-control.StripeElement.StripeElement--empty {
  padding: 15px !important;
}
