.card-layout {
  /* background-image: url(../../../../assets/images/popol-coin.png);
  background-position: -100px center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #dbb42ca7; */

  border: 1px solid #eee;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 330px;
}
.card-layout:hover {
  box-shadow: 0px 0px 14px #ddd;
  background-color: #fff;
}
.card-price {
  background: linear-gradient(310deg, #0842ac, #318ce7);
  background-color: linear-gradient(310deg, #0842ac, #318ce7);
  color: #0842ac;
  width: 150px;
  height: 150px;
  border-radius: 100px !important;
  display: flex;
  align-items: center;
  font-size: 34px;
  justify-content: center;
  font-weight: 600;
  margin-top: 30px;
  position: relative;
  z-index: 1;
}
.card-price::before {
  content: '';
  color: #fff;
  background-color: #fff;
  width: 140px;
  height: 140px;
  border-radius: 100px !important;
  position: absolute;
  z-index: -1;
}

.card-heading {
  text-align: center;
  padding: 25px 20px 0px 20px;
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  color: #0842ac;
  width: 100%;
  border-radius: 5px;
}
.card-sub-heading {
  text-align: center;
  padding: 0px 20px 0px 20px;
  font-size: 17px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  color: #0842ac;
}

.card-price-section {
  min-width: 100%;
  margin-top: 20px;
  background-color: #0842ac;
}

.card-btn {
  display: flex;
  justify-content: center;
  padding: 10px;
  font-size: 24px;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
  background: linear-gradient(310deg, #0842ac, #318ce7);
}
.card-btn:hover {
  background-color: #02026c;
  background: linear-gradient(190deg, #318ce7, #0842ac);
}
p {
  margin-bottom: 0 !important;
}

a {
  color: #0842ac !important;
}