// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

$bootstrap-sass-asset-helper: false !default;
//
// Variables
// --------------------------------------------------

// Colors

$title-color: #0842ac;
$theme-color: #28dbd1;
$primary-color: #0a1f2f;
$secondary-color: #e84217;

$border-color: #a2a3e4;
$white-color: #fff;
$blue-color: #0842ac;
$ash-color: #2f3d5f;
$body-color: #02121d;
$black-color: #000000;
$text-color: #c1c7cb;
$dark-color: #191919;

$success-color: #12ff4d;
$fail-color: #ff2812;

// social-media color
$facebook: #3b5998;
$twitter: #55acee;
$linkedin: #007bb5;
$skype: #2fb7df;
$youtube: #cd201f;
$google: #dd4b39;
$camera: #e4405f;
$instagram: #e4405f;
$tumblr: #34465d;
$behance: #1769ff;
$star: #e9ba17;
$green: #0be881;
$green-h: #0fbcf9;
$red: #f53b57;
$yellow: #dec32b;
$nell: #212fb4;
$navy: #d8e0f3;
$placeholder: #a0a6bc;

//== Typography

// font-family

$text-font: 'Roboto', sans-serif;

$title-font: 'Rajdhani', sans-serif;

// font-size

$fs-base: 16px !default;
$fs-small: 14px;

$fs-h1: 65px;
$fs-h2: 44px;
$fs-h3: 32px;
$fs-h4: 24px;
$fs-h5: 22px;
$fs-h6: 20px;

//  transition
$transition: all 0.3s ease;
