.mship-card-body {
  /* background-image: url('../../../../assets/images/member-cards/1-student.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  position: relative;
  width: 360px;
  /* background: #eee; */
}
/* .mship-detail-secion {
  width: 100%;
  position: relative;
} */
.mship-detail-secion {
  width: 90%;
  margin-top: 85px;
  margin-left: 17px;
  padding-left: 6px;
  position: absolute;
  text-shadow: 1px 1px #444;
}
.mship-card-name {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  padding-bottom: 10px;
}
.mship-title {
  color: #fff;
  font-size: 13px;
  position: relative;
  text-transform: uppercase;
}
.buy-date {
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-top: 35px;
  padding-right: 15px;
  justify-content: space-between;
}
.mship-code {
  font-size: 12px;
  color: #fff;
  background-color: #333;
  margin-top: 7px;
  border: 1px solid #555;
  width: 50%;
  padding: 3px;
  border-radius: 5px;
  text-align: center;
}
.price-box {
  margin-bottom: 10px;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background-color: #fff;
  color: #0842ac;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  display: flex;
}

.memb-card {
  border-radius: 10px;
  max-width: 100%;
}

@media screen and (min-width: 1150px) and (max-width: 1399px) {
  .memb-card {
    border-radius: 10px;
    max-width: 80%;
  }
  .mship-detail-secion {
    width: 90%;
    margin-top: 65px;
    margin-left: 17px;
    padding-left: 6px;
    position: absolute;
    text-shadow: 1px 1px #444;
  }
  .mship-card-name {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    padding-bottom: 5px;
  }
  .mship-title {
    color: #fff;
    font-size: 13px;
    position: relative;
    text-transform: uppercase;
  }
  .buy-date {
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: 12px;
    padding-right: 15px;
    justify-content: space-between;
  }
}
@media screen and (min-width: 990px) and (max-width: 1149px) {
  .memb-card {
    border-radius: 10px;
    max-width: 80%;
  }
  .mship-detail-secion {
    width: 90%;
    margin-top: 65px;
    margin-left: 17px;
    padding-left: 6px;
    position: absolute;
    text-shadow: 1px 1px #444;
  }
  .mship-card-name {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    padding-bottom: 5px;
  }
  .mship-title {
    color: #fff;
    font-size: 13px;
    position: relative;
    text-transform: uppercase;
  }
  .buy-date {
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: 12px;
    padding-right: 15px;
    justify-content: space-between;
  }
}
@media screen and (min-width: 250px) and (max-width: 760px) {
  .memb-card {
    border-radius: 10px;
    max-width: 80%;
  }
  .mship-detail-secion {
    width: 90%;
    margin-top: 65px;
    margin-left: 17px;
    padding-left: 6px;
    position: absolute;
    text-shadow: 1px 1px #444;
  }
  .mship-card-name {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    padding-bottom: 5px;
  }
  .mship-title {
    color: #fff;
    font-size: 13px;
    position: relative;
    text-transform: uppercase;
  }
  .buy-date {
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: 12px;
    padding-right: 15px;
    justify-content: space-between;
  }
}
