.modal-heading {
  color: #0842ac;
  font-size: 26px;
  padding: 20px 10px 10px 0px;
  margin: 0px;
  font-weight: bold;
}

.modal-body {
  margin: 0px;
  padding: 0px !important;
  font-weight: bold !important;
}

.modal-body h2 {
  font-size: 20px;
  font-weight: bold;
}

.connect-box {
  border: 1px solid #eee;
  padding: 10px;
  display: flex;
  border-radius: 6px;
  align-items: center;
  margin: 10px 10px 10px 0px;
}
.connect-box:hover {
  background-color: #eee;
  border: 1px solid #ddd;
  cursor: pointer;
}
.connect-box img {
  margin-right: 20px;
}
.connect-box h2 {
  padding-top: 10px;
}
